<template>
  <v-container>
     <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" class="pb-3" />
    <v-data-table
      :headers="headers"
      :items="configParentescos"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top >
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()" class="to-right">
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="openModal(item.configId)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar parentesco</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="deleteParentesco(item.configId)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar parentesco</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="500px"
      @keydown.esc="closeModal"
      persistent
    >
      <EditParentesco
        :configId="configId"
        @closeAndReload="closeAndReload"
      ></EditParentesco>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
    </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditParentesco from "@/components/modules/appBenef/configuracion/EditParentesco.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ParentescosApp",
  components: { PageHeader, DeleteDialog, EditParentesco, GoBackBtn, Ayuda },

  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    showDeleteModal: false,
    parentescosCombo: [],
    responsableSelected: null,
    aCargoSelected: null,
    search: "",
    searchIcon: enums.icons.SEARCH,
    idToDelete: null,
    formEditTitle: "",
    editParentescosDialog: false,
    routeToGo: "ConfiguracionAppBenef",
    title: enums.titles.PARENTESCOS_APP,
    optionCode: enums.webSiteOptions.PARENTESCOS_APP,
    showHelp: false,
    showExpand: false,
    showIcon: false,
    titleDelete: "¿Eliminar parentesco?",
    rules: rules,
    configParentescos: [],
    parentesco: {},
    headers: [
      {
        text: "Responsable",
        align: "start",
        value: "parNombreResponsable"
      },
      {
        text: "A cargo",
        value: "parNombre"
      },
      {
        text: "Edad límite",
        value: "edadLimite"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadParentescos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getConfiguracionParentescos: "configAppBenef/getConfiguracionParentescos",
      deleteConfiguracionParentescos:
        "configAppBenef/deleteConfiguracionParentescos",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_PARENTESCO:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_PARENTESCO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_PARENTESCO:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadParentescos() {
      const response = await this.getConfiguracionParentescos();
      this.configParentescos = response;
    },
    deleteParentesco(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteConfiguracionParentescos({
        configId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadParentescos();
      }
    },
    openModal(configId) {
      this.openModalEdit = true;
      this.configId = configId;
    },
    closeModal() {
      this.openModalEdit = false;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadParentescos();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
