<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-select
                v-model="responsableSelected"
                ref="par-responsable"
                :items="parentescosCombo"
                item-text="value"
                item-value="id"
                label="Responsable"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-select
                v-model="aCargoSelected"
                ref="par-a-cargo"
                :items="parentescosCombo"
                item-text="value"
                item-value="id"
                label="A cargo"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="edadLimite"
                label="Edad límite"
                item-text="number"
                item-value="id"
                dense
                outlined
                onkeypress="return (event.charCode >= 46 && event.charCode <= 57)"
                :rules="rules.required.concat(rules.max99)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditParentesco",
  props: ["configId"],
  data: () => ({
    isFormValid: false,
    parentescosCombo: [],
    responsableSelected: null,
    aCargoSelected: null,
    formEditTitle: "Editar parentesco",
    editParentescosDialog: false,
    title: enums.titles.PARENTESCOS_APP,
    rules: rules,
    edadLimite: null,
    parentescos: []
  }),
  created() {
    if (this.configId != null) {
      this.setParentesco(this.configId);
    } else {
      this.newParentesco();
    }
    this.loadParentescos();
  },
  methods: {
    ...mapActions({
      getParentescos: "configAppBenef/getParentescos",
      getConfiguracionParentescosById:
        "configAppBenef/getConfiguracionParentescosById",
      postConfiguracionParentescos:
        "configAppBenef/postConfiguracionParentescos",
      setAlert: "user/setAlert"
    }),
    async loadParentescos() {
      const data = await this.getParentescos();
      this.parentescos = data;
      this.loadParentescosCombo();
    },
    loadParentescosCombo() {
      this.parentescosCombo = [...this.parentescos];
      // ver si hay que validar que el responsable no este en la lista de a cargo y al reves
    },
    async setParentesco() {
      const response = await this.getConfiguracionParentescosById(
        this.configId
      );
      this.aCargoSelected = response.parId;
      this.edadLimite = response.edadLimite;
      this.responsableSelected = response.parIdResponsable;
    },
    async newParentesco() {
      this.formEditTitle = "Nuevo parentesco";
      this.parentescosCombo = [...this.parentescos];
    },
    async saveEdit() {
      const data = {
        configId: this.configId,
        parId: this.aCargoSelected,
        parIdResponsable: this.responsableSelected,
        edadLimite: this.edadLimite
      };
      const res = await this.postConfiguracionParentescos(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
